import logo from "../assets/logo.png"

export const Header = () => {
  return (
    <header className="header">
      <div className="title-bar">
        <img src={logo} alt="logo" /> <h1>Sponsoring Hack@Brown</h1>
      </div>
      <hr />
    </header>
  )
}
