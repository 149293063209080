import thumbnail2024 from "../assets/2024-2.png"
import thumbnail2023 from "../assets/2023-2.png"
import thumbnail2022 from "../assets/2022-2.png"
import thumbnail2021 from "../assets/2021-2.png"

export const PastWebsites = () => {
  return (
    <ul className="past-websites">
      <li>
        <a
          href="https://2024.hackatbrown.org/"
          className="underline-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={thumbnail2024}
            alt="Hack@Brown 2024"
            className="past-thumbnail"
          />
        </a>
      </li>
      <li>
        <a
          href="https://2023.hackatbrown.org/"
          className="underline-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={thumbnail2023}
            alt="Hack@Brown 2023"
            className="past-thumbnail"
          />
        </a>
      </li>
      <li>
        <a
          href="https://2022.hackatbrown.org/"
          className="underline-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={thumbnail2022}
            alt="Hack@Brown 2022"
            className="past-thumbnail"
          />
        </a>
      </li>
      <li>
        <a
          href="https://2021.hackatbrown.org/"
          className="underline-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={thumbnail2021}
            alt="Hack@Brown 2021"
            className="past-thumbnail"
          />
        </a>
      </li>
    </ul>
  )
}
