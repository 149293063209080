export const Navbar = () => {
  return (
    <div>
      <div className="navbar content-wrapper">
        <div className="message">
          Sponsor a hackathon with 500+ attendees from 30+ different schools!
        </div>
        <div
          style={{
            display: "flex",
            gap: "8px",
          }}
        >
          <a href="https://docs.google.com/presentation/d/1mkPn938k9oJNHpZebCNWUwsvHyI-Q_Ekhp4BbC8am6s/edit?usp=sharing">
            <button className="button outline">2024 Impact Report</button>
          </a>
          <a href="https://calendly.com/sponsor-hackatbrown/prospectus-sponsor-meeting?month=2024-07">
            <button className="button filled">Schedule a Meeting!</button>
          </a>
        </div>
      </div>
      <div style={{ height: "80px" }}></div>
    </div>
  )
}
