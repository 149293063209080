import { motion } from "framer-motion"

export const WhyList = () => {
  const h3Variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <ul className="why-list">
      <li>
        <motion.h3
          style={{ color: "#180467", marginBottom: "0%" }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }} // Ensures the animation triggers only once when it comes into view
          variants={h3Variants}
          transition={{ duration: 0.25, delay: 0.15 }}
        >
          <div>01</div>
          Promote Diversity and Inclusion
        </motion.h3>
        <p style={{ marginTop: "1%", marginBottom: "3%" }}>
          Access <b>diverse tech talent</b> and bridge the representation gap in
          the industry by supporting Hack@Brown. For our 2025 hackathon, we are
          partnering again with Brown University Women in Computer Science and
          MOSAIC+ to drive our commitment to diversity further. Last year, our
          attendees were <b>43%</b> women and ~25% were from{" "}
          <b>underrepresented</b> racial minorities in tech. Additionally, our
          organizing team board is majority women, at 71%.
        </p>
      </li>
      <hr />
      <li>
        <motion.h3
          style={{ color: "#180467", marginBottom: "0%" }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={h3Variants}
          transition={{ duration: 0.25, delay: 0.15 }}
        >
          <div>02</div>
          Meet Talented Students
        </motion.h3>
        <p style={{ marginTop: "1%", marginBottom: "3%" }}>
          By sponsoring Hack@Brown, you’ll get the chance to meet amazing
          computer science, engineering, and design students from{" "}
          <b>30+ different schools</b>, including Brown, RISD, MIT, and
          Columbia. Along with this, you will get{" "}
          <b>access to 350+ applicant resumes</b> and portfolios to help with
          your recruiting efforts. Our hackathon brings together some of the
          brightest students from across the country who are eager to imagine
          and build technical products.
        </p>
      </li>
      <hr />
      <li>
        <motion.h3
          style={{ color: "#180467", marginBottom: "0%" }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={h3Variants}
          transition={{ duration: 0.25, delay: 0.15 }}
        >
          <div>03</div>
          Showcase your Company and Brand
        </motion.h3>
        <p style={{ marginTop: "1%", marginBottom: "3%" }}>
          Have the opportunity to boost your company's brand, mission, and
          technology through our <b>sponsorship fair</b> and{" "}
          <b>company-led workshops!</b> In the past, we’ve had workshops hosted
          by companies like Google and MongoDB on topics ranging from machine
          learning to security engineering, and{" "}
          <b>we would love for you to host our next one.</b>
        </p>
      </li>
    </ul>
  )
}
