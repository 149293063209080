import hackatbrown2025 from "../assets/hab2025.jpeg"

export const Meet = () => {
  return (
    <div className="meet">
      <div>
        <p>
          We would love to meet with you to discuss how you can contribute! Feel
          free to set up a meeting on our{" "}
          <a
            href="https://calendly.com/sponsor-hackatbrown/prospectus-sponsor-meeting?month=2024-07"
            className="calendly-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Calendly
          </a>
          .
        </p>
        <p style={{ marginTop: "4em", marginBottom: "2em" }}>
          If you have any questions, please reach out to us at{" "}
          <a href="mailto:sponsor@hackatbrown.org" className="calendly-link">
            sponsor@hackatbrown.org
          </a>
          !
        </p>
      </div>
      <div>
        <img src={hackatbrown2025} alt="paw" className="paw" />
      </div>
    </div>
  )
}
