import React from "react"
import "./SponsorInfo.css"
import { SponsorTable } from "./SponsorTable"
import { Meet } from "./MeetingButton"
import { WhoBody } from "./WhoBody"
import { WhyList } from "./WhyList"
import { PastWebsites } from "./PastWebsites"
import { Header } from "./Header"
import { Navbar } from "./Navbar"
import { StatBar } from "./StatBar"
import { Footer } from "./Footer"

const SponsorInfo = () => {
  return (
    <main className="sponsor-container container">
      <Navbar />
      <Header />
      <div className="sponsorship-doc content-wrapper">
        <section>
          <div className="who-section">
            <div>
              <h2 className="section-header">WHO ARE WE?</h2>
              <WhoBody />
            </div>
            <div>
              <PastWebsites />
            </div>
          </div>
        </section>
      </div>

      <StatBar />

      <div className="sponsorship-doc content-wrapper">
        <h2 className="section-header">WHY SPONSOR US?</h2>
        <WhyList />
      </div>

      <div className="content-wrapper color-wrapper">
        <div class="table-section-wrapper">
          <h2 className="section-header">SPONSORSHIP TIERS</h2>
          <SponsorTable />
        </div>
      </div>

      <div className="sponsorship-doc content-wrapper">
        <Meet />
      </div>

      <Footer />
    </main>
  )
}

export default SponsorInfo
