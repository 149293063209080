import React from "react";
import SponsorInfo from "../components/SponsorInfo";

const SponsorHome = () => {
    return(


        
        <div>
            <SponsorInfo/>
            
        </div>

        
    )
}

export default SponsorHome;