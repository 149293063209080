export const WhoBody = () => {
  return (
    <div>
      <p>
        Hack@Brown is Brown University's premier hackathon, bringing together
        350-500 students annually from top universities across the country since
        2014 and returning for its 11th iteration on <b>February 1-2, 2025!</b>
      </p>
      <p>
        Hack@Brown is a weekend-long event where participants can learn about
        new technologies and <b>build innovative projects</b>. We are all about
        creating an <b>all-inclusive hackathon environment</b> for students,
        from beginners to seasoned hackers, to explore design, software, and
        hardware. As the biggest tech event on campus, Hack@Brown provides
        budding entrepreneurs and builders with software starter kits, mentors,
        and workshops to build impactful, technical projects.
      </p>
    </div>
  )
}
