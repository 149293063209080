import SponsorHome from "./pages/SponsorHome";


function App() {
  return (
    <div className="App">
      <SponsorHome/>
      
    </div>
  );
}

export default App;
