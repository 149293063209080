import { motion } from "framer-motion"

export const StatBar = () => {
  const statVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <div className="stat-bar">
      <motion.div
        className="stat"
        initial="hidden"
        variants={statVariants}
        whileInView="visible"
        transition={{ duration: 0.5, delay: 0 }}
      >
        <i className="fas fa-user fa-3x"></i>
        <h3>500+</h3>
        <p>Attendees</p>
      </motion.div>
      <motion.div
        className="stat"
        initial="hidden"
        variants={statVariants}
        whileInView="visible"
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <i className="fas fa-school fa-3x"></i>
        <h3>30+</h3>
        <p>Schools</p>
      </motion.div>
      <motion.div
        className="stat"
        initial="hidden"
        variants={statVariants}
        whileInView="visible"
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <i class="fas fa-child fa-3x"></i>
        <h3>43%</h3>
        <p>Women Attendees</p>
      </motion.div>
      <motion.div
        className="stat"
        initial="hidden"
        variants={statVariants}
        whileInView="visible"
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <i class="fas fa-hand-holding-heart fa-3x"></i>
        <h3>25%</h3>
        <p>Underrepresented Minorities</p>
      </motion.div>
    </div>
  )
}
